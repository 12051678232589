.video {
  position: relative;
  height: 100%;
  .img {
    width: 100%;
    display: block;
  }
  video {
    display: block;
  }
  .section {
    position: absolute;
    height: 100%;
    left: calc(50% - 720px);
    top: 0;
  }
  .text {
    position: absolute;
    bottom: 275px;
    left: 660px;
    font-size: 64px;
    color: #fff;
    text-transform: uppercase;
    line-height: 1;
    span {
      color: #F5F5F5;
      opacity: .5;
    }
  }
}

@media all and (max-width: 1439px) {
  .video {
    .section {
      width: 100%;
      max-width: 100%;
      left: 0;
    }
    .text {
      left: 50%;
      top: calc(50% - 32px);
    }
  }
}

@media all and (max-width: 940px) {
  .video {
    .text {
      font-size: 34px;
    }
  }
}